@tailwind base;
@tailwind components;
@tailwind utilities;

.footer-fixed{
    position: fixed;
    bottom: 0px;
    background: #EFEFEF;
    height: 60px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 15px;
}


.css-1s2u09g-control{
    border-radius: 50px !important;
    border: 1px solid rgb(156 163 175 / 500) !important;
}